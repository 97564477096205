@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	padding: 0;
	width: 100%;
	text-rendering: optimizeLegibility;
	touch-action: manipulation;
	-webkit-user-drag: none;
	-ms-content-zooming: none;
	word-wrap: break-word;
	overscroll-behavior-y: none;
	-webkit-text-size-adjust: none;
	text-size-adjust: none;
	overflow: auto !important;
}

#root {
	min-height: 100vh;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.h-modal {
	height: 100%
}